import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={9}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 9</H1>

        <div className="mt-5 w-2/3 flex content-end">
          <StaticImage
            alt="Hand using tablet."
            className="rounded"
            src="../../../images/lesson_4_step_9.jpg"
            loading="eager"
            placeholder="blurred"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          />
        </div>

        <p>There is another type of product sold in stores. These are called supplements. Supplements are defined as products taken by mouth that contain a "dietary ingredient." Dietary ingredients are things like vitamins, minerals, herbs, and plant products. Supplements can come in many forms, such as pills, tablets, capsules, powders, energy bars, or liquids. Supplements are sold in stores and through the internet.</p>
        <p>Some supplements may help people, but, like other products, there can be risks. There are times when supplements can be harmful to some people. Sometimes, people think that because a product is natural, it is safe. Even supplements derived from plants can have an unwanted side effect if used improperly.</p>
        <p>The FDA does <em>not</em> review dietary supplements for safety or effectiveness. The companies that make the supplements are supposed to make sure they are safe and made in a quality manner. The FDA can step in if it is found that a supplement puts people at risk or if it is not what it claims to be.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

